/* @import "ng-zorro-antd/ng-zorro-antd.min.css"; */
/*FONTS */
@font-face {
    font-family: Encode Sans Regular;
    src: url('./assets/fonts/EncodeSansExpanded-Regular.ttf');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: Encode Sans Black;
    src: url('./assets/fonts/EncodeSansExpanded-Black.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: Encode Sans Bold;
    src: url('./assets/fonts/EncodeSansExpanded-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: Encode Sans ExtraBold;
    src: url('./assets/fonts/EncodeSansExpanded-ExtraBold.ttf');
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: Encode Sans ExtraLight;
    src: url('./assets/fonts/EncodeSansExpanded-ExtraLight.ttf');
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: Encode Sans Light;
    src: url('./assets/fonts/EncodeSansExpanded-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: Encode Sans Medium;
    src: url('./assets/fonts/EncodeSansExpanded-Medium.ttf');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: Encode Sans SemiBold;
    src: url('./assets/fonts/EncodeSansExpanded-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: Encode Sans Thin;
    src: url('./assets/fonts/EncodeSansExpanded-Thin.ttf');
    font-weight: 100;
    font-display: swap;
}

/* Supports safari */
@supports (-webkit-touch-callout: none) {
    input#fechaReserva {
        padding-top: 0px !important;
        padding-bottom: 10px !important;
        line-height: -15px !important;
        height: 40px !important;
        position: relative;
        top: -6px;
    }

    input#fechadevuelta {
        padding-top: 0px !important;
        padding-bottom: 10px !important;
        line-height: -15px !important;
        height: 40px !important;
        position: relative;
        top: -6px;
    }
}


#loader {
    width: 200px;
    height: 200px;
    background-image: url('https://21623072.fs1.hubspotusercontent-na1.net/hubfs/21623072/Rentacar%20Assets/Assets/loading.gif');
    background-size: cover;
    /* animation: spin 1s infinite linear; */
}

.container-loader {
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 10;
}

.container #loader {
    margin: auto;
}

/* You can add global styles to this file, and also import other style files */

.background-body {
    /*   background: transparent linear-gradient(180deg, #1E4897 0%, #0F234A 100%) 0% 0% no-repeat padding-box;*/
    margin: 0;
}

.backgroung-white {
    background-color: #ffffff;
}

.maright-30 {
    margin-right: 30px;
}

.btn-rounded {
    border-radius: 25px;
    border: none;
    height: 40px;
    font-family: Encode Sans Bold;
    line-height: 17px;
    cursor: pointer;
    padding: 0 20px;
}

.btn-rounded:disabled,
.btn-rounded[disabled] {
    background-color: #cccccc !important;
    color: #666666;
}

.container_features_btnSelect button:disabled,
.container_features_btnSelect button[disabled] {
    background-color: #cccccc !important;
    color: #666666;
}

.bg-green {
    background: transparent linear-gradient(95deg, #55970C 0%, #467D0A 100%, #467D0A 100%) 0% 0% no-repeat padding-box;
    color: #ffffff;
    padding: 0 20px;
}

a.bg-green {
    padding: 0;
}

.bg-red {
    background: #e62e1b;
    color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    padding: 0 20px;
}

.bg-orange {
    background: orange;
    color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    padding: 0 20px;
}

.bg-green:hover,
.bg-red:hover,
.bg-orange:hover {
    color: #1E4897;
}

.bg-white {
    background: white;
    color: #1E4897;
    border: 1px solid #1E4897;
    padding: 0 30px;
    min-width: 250px;
}

.bg-green:disabled {
    background: gray;
    color: #ffffff !important;
    border: none;
}

.bg-green:hover {
    color: #1E4897;
}

.mat-slide-toggle-content {
    font-family: Encode Sans Bold;
    font-size: 15px;
    color: #1E4897;
}


.text-regular {
    font-family: Encode Sans Regular;
    font-size: 12px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #FFFFFF;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #55970C;
}

input#phone:focus-visible,
input#phone2:focus-visible {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    border-radius: .25rem;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}


.link-green {
    color: #55970C;
    font-family: Encode Sans Regular;
    font-size: 12px;
    line-height: 15px;
    margin: 20px 0 25px;
    text-decoration: none;
    cursor: pointer;
}

a.btn-rounded.bg-green.link-green-select {
    text-decoration: none;
}

.div-text-link {
    background: #55970C;
    padding: 12px 0;
    text-align: center;
    border-radius: 25px;
    margin: 20px 0;
}

.div-text-link p {
    margin: 0;
}

.buttondivmore {
    max-width: 1200px;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
}

.card-login {
    background: white;
    border-radius: 20px;
    max-width: 400px;
    display: flex;
    align-items: center;
    height: fit-content;
    position: inherit;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    flex-wrap: wrap;
    flex-direction: column;
}

.card-login a img {
    height: auto;
    margin: 20px;
}

.card-login span {
    font-family: Encode Sans Regular;
    font-size: 14px;
    color: #1E4897;
    line-height: 18px;
    /*width: 80%;*/
}

.formulario-login {
    width: calc(100% - 50px);
    padding: 25px;
    display: flex;
    flex-direction: column;
}

.formulario-contacto {
    width: 60%;
    padding: 25px;
    display: flex;
    flex-direction: column;
}

.form-group {
    display: contents;
    width: 100% !important;
    display: grid;
}


.form-group label {
    font-family: Encode Sans Bold;
    color: #1E4897;
    font-size: 12px;
    line-height: 26px;
    margin: 10px 0;
}

.form-group input,
.form-group textarea,
.form-group select {
    font-family: 'Encode Sans Regular';
    font-size: 12px;
    line-height: 40px;
    color: #333333;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px #1E489750;
    -webkit-appearance: auto;
    max-height: 50px;
}

.form-group select {
    min-height: 50px;
}

.md-stepper-horizontal .md-step.editable:hover {
    cursor: default;
}

.md-stepper-horizontal .md-step.done:hover, .md-stepper-horizontal .md-step:hover {
    cursor: no-drop;
}

.md-stepper-horizontal .md-step.canmove:hover {
    cursor: pointer;
}

.custom-field-phone {
    min-width: 100%;
    padding-top: .375rem;
    padding-bottom: .375rem;
}

.form-group ngx-intl-tel-input .iti {
    width: 100%;
}




.fondo-azul {
    background: transparent linear-gradient(180deg, #1E4897 0%, #0F234A 100%) 0% 0% no-repeat padding-box;
    /* height: 100vh; */
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;

}



.contenedor {
    max-width: 1200px;
    margin: 120px auto;
    width: 100%;
    margin-bottom: 60px;
}

.titulo-azul-h1 {
    font-family: Encode Sans Black;
    color: #1E4897;
    font-size: 40px;
    text-transform: uppercase;
    line-height: 45px;
    margin-bottom: 25px;
}

.titulo-azul-h3 {
    color: #1E4897;
    font-family: 'Encode Sans Regular';
    line-height: 12px;
    font-size: 14px;
    padding: 15px 0 0;
    letter-spacing: 0.35px;
}

button.btn-rounded.bg-white:hover {
    color: #55970C;
}

.titulo-verde-h4 {
    color: #55970C;
    font-size: 16px;
    font-family: 'Encode Sans Black';
    line-height: 12px;
}

.titulo-azul-24 {
    color: #1E4897;
    font-size: 24px;
    font-family: 'Encode Sans Bold';
    line-height: 23px;
}

.titulo-azul-cat {
    color: #1E4897;
    font-size: 20px;
    font-family: 'Encode Sans Bold';
    line-height: 23px;
}


.span-condiciones {
    color: #1E4897;
    font-family: Encode Sans Regular;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.35px;
    padding: 10px 0 0 0;
}

.formulario-doble {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.columna-form-doble {
    width: calc(50% - 40px);
    padding: 0 40px 0 0;
    margin-bottom: 60px;
}

.btn-save-rigth {
    margin-top: 25px;
    margin-top: 25px;
    width: 350px;
    margin: auto;
    margin-right: 0;
}

.link-button-right {
    padding: 10px 20px !important;
    width: 100%;
    margin: auto;
    text-decoration: none;
}

@media (max-width:1200px) {
    .contenedor {
        margin: 120px auto;
        width: 90%;

    }
}

@media (max-width:768px) {
    .columna-form-doble {
        width: 100%;
        padding: 20px 0;
    }

    .contenedor {
        max-width: 1200px;
        margin: 120px auto 60px;
        width: 90%;
    }

    .titulo-azul-h1 {
        font-size: 30px;
        line-height: 35px;
    }

    .columna-form-doble {
        margin-bottom: 0px !important;
    }
}


@media (max-width:400px) {
    .card-login {
        margin: auto 10px;
    }

    .btn-save-rigth {
        width: 90%;
        margin: auto;
    }

    .formulario-login {
        padding: 25px 0 !important;
    }

}

@media (max-height:900px) {
    .card-login {
        position: initial !important;
    }

    .fondo-azul {
        background: transparent linear-gradient(180deg, #1E4897 0%, #0F234A 100%) 0% 0% no-repeat padding-box;
        height: auto;
    }
}

a.btn-rounded.bg-white {
    text-decoration: none;
    padding: 10px 30px;
}

.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #22222295;
    z-index: 2;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}


/************HOME STYLES*************/
/*FEATURES BANNER*/

.features-banner {
    display: flex;
    max-width: 1200px;
    margin: 0px auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 15px 0;
}

.feature-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.feature-item img {
    padding-right: 15px;
}

.feature-item-text {
    margin: auto;
    font-family: 'Encode Sans Regular';
    color: white;
    font-size: 16px;
    line-height: 23px;
    text-align: left;
}

.feature-item-text p {
    margin: auto;
}

.feature-item-text span {
    font-family: 'Encode Sans Bold';
}


/***CARROUSEL**/
.carusel-home {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px !important;
}

/* CATEGORIES SECTION */
.categories-section {
    /* max-width: 1200px; */
    max-width: 100vw;
    margin-right: auto;
    margin-left: auto;
    padding: 30px 0;
    text-align: center;
    min-height: 250px;
}

.content-categorie img {
    width: 160px;
    height: auto;
    margin: 0 auto;
}

.cards-categories-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.card-categorie {
    box-shadow: 10px 10px 35px #00000029;
    border-radius: 20px;
    padding: 30px;
    height: 210px;
    width: calc(20% - 20px);
    margin: 90px auto !important;
    text-decoration: none;
    display: flex !important;
    align-items: flex-end;
    place-content: center;
}

.cards-categories-container .slick-list.draggable .slick-track .slick-slide div {
    margin: 0 10px;
}


.content-categorie p {
    color: #1E4897;
    font-size: 12px;
    line-height: 23px;
    font-family: 'Encode Sans Regular';
}

.listnameicon {
    display: flex;
    align-items: center;
}

.listnameicon p {
    margin: auto;
    padding-right: 20px;
}

.content-categorie a {
    text-decoration: none;
    cursor: pointer;
    padding: 5px 30px;
    /* max-width: none; */
}

.form-group textarea {
    min-height: 100px;
}

/* NEW MODELS SECTION */
.newmodels-section {
    /* max-width: 1200px;*/
    max-width: 100vw;
    margin-right: auto;
    margin-left: auto;
    padding: 30px 0;
    text-align: center;
    min-height: 250px;
}


img.icon-sale {
    max-width: 60px;
    margin: 0 0 0 auto !important;
    max-height: 50px;
}

.div-iconsale {
    width: 100%;
    transform: translateY(60px);
}

.content-model {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    text-align: left;
    padding: 0 15px;
    min-height: 200px;
}


.info-model {
    color: #1E4897;
    font-family: 'Encode Sans Regular';
    font-size: 10px;
    line-height: 15px;
    padding-right: 15px;
}

.info-model h4 {
    font-size: 18px;
    font-family: 'Encode Sans Bold';
    line-height: 22px;
    text-transform: uppercase;
}

ul.list-info-model {
    list-style: none;
    padding: 0;
    columns: 2;
    color: #1E4897;
    font-size: 10px;
    line-height: 15px;
    font-family: 'Encode Sans Regular';
}

.info-model p {
    margin: auto;
}

.forsale-section {
    max-width: 1200px;
    margin: 0px auto;
    padding: 30px 0;
    text-align: center;
    min-height: 300px;
}

.slide-onsale-container div ul {
    list-style: none;
    padding: 0;
    columns: 1;
    color: #1E4897;
    font-size: 10px;
    line-height: 15px;
    font-family: 'Encode Sans Regular';

}

/**OFFFERS**/
.banner-oferta {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
}

.banner-oferta img {
    width: 100%;
}


/*BLOG**/
.ideas-section {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    text-align: center;
}

.ideas-container {
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
    margin: 30px 0;
}

.blog-card {
    width: calc(25% - 25px);
    box-shadow: 10px 10px 45px #00000029;
    border-radius: 20px;
    text-align: center;
}

.content-article {
    text-align: center;
    padding: 15px;
}

.content-article h4 {
    color: #1E4897;
    font-family: 'Encode Sans Bold';
    font-size: 18px;
    line-height: 22px;
    height: 45px;
    overflow: hidden;
    text-align: left;
}

.content-article span {
    color: #333333;
    font-family: 'Encode Sans Regular';
    font-size: 10px;
    line-height: 15px;
    text-align: left;
}

.content-article p {
    color: #1E4897;
    font-size: 10px;
    line-height: 15px;
    font-family: 'Encode Sans Regular';
    height: 60px;
    overflow: hidden;
    text-align: left;
}

.content-article a {
    width: 100%;
}

.blog-card img {
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    border: 5px solid #fff;
    height: 180px;
    object-fit: cover;
}


/*MAP STYLE**/
.map-section {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    text-align: left;
}

.map-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.list-office {
    width: 40%;
    padding-right: 30px;
    max-height: 510px;
    height: 510px;
    /* max-height: 450px;
    height: 450px;*/
    overflow-y: auto;
}

.list-office #list-map {
    margin-bottom: 0;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

.map-pins {
    max-height: 450px;
    height: 450px;
    box-shadow: 15px 15px 45px #00000029;
    border-radius: 20px;
    width: 60%;
}

.list-map {
    list-style: none;
    padding: 0;
}

.list-map li a {
    display: flex;
    justify-content: space-between;
    font-family: 'Encode Sans Bold';
    font-size: 14px;
    line-height: 22px;
    color: #1E4897;
    text-decoration: none;
    padding: 10px 0;
}

.list-map li:hover a {
    color: #55970C !important;
}

.list-map li {
    border-bottom: solid 1px #1E489720;
    cursor: pointer;
}

.list-map li a div img {
    margin-right: 20px;
}

.info-office-map {
    width: 250px;
    text-align: center;
    margin: 30px 0;
}

.info-office-map h5 {
    color: #1E4897;
    font-size: 10px;
    font-family: 'Encode Sans Bold';
    line-height: 22px;
    margin: auto;
    text-align: left;
}

.info-office-map p {
    color: #1E4897;
    font-size: 8px;
    line-height: 12px;
    font-family: 'Encode Sans Regular';
    text-align: left;
}

.gm-style-iw.gm-style-iw-c {
    min-height: 130px;
}

.gm-style .gm-style-iw {
    overflow: hidden;
}

.info-office-map p span {
    font-family: 'Encode Sans Bold';
}

.lista-map-office-select {
    display: none;
}

.lista-map-office-select {
    font-family: 'Encode Sans Regular';
    font-size: 12px;
    line-height: 40px;
    color: #333333;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px #1E489750;

}

.lista-map-office-select {
    padding: 10px 0;
}

@media (max-width:1200px) {
    .card-categorie {
        width: calc(25% - 20px);
        ;
    }

    .forsale-section {
        margin: auto 30px;
    }

    .ideas-section {

        margin-left: 20px;
        margin-right: 20px;
    }

    .map-section {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media (max-width:991px) {
    .card-categorie {
        width: calc(50% - 20px);
    }

    .blog-card {
        width: calc(50% - 25px);
        margin-bottom: 20px;
    }

    .most-read-column {
        width: 100%;
    }

}

@media (max-width:768px) {
    .list-map {
        display: none;

    }

    .lista-map-office-select {
        display: block;
        width: 100%;
    }

    .list-office {
        width: 100%;
        padding-right: 0;
        height: auto;
        margin: 0 0 30px;
    }

    .map-pins {

        width: 100%;
        margin: 0px 0 30px;
    }

    .info-model p{
        font-size: 12px;
    }
    
    ul.list-info-model {
        font-size: 12px !important;

    }
}

@media (max-width:767px) {
    .feature-item img {
        padding: 10px;
    }

    .feature-item-text {
        text-align: center;
    }

    .feature-item {
        display: block;
        text-align: center;
        margin: auto;
        padding: 15px;
    }

    .features-banner {
        display: block;
    }

    .content-model {
        display: block;
        text-align: center;
    }

    ul.list-info-model {

        margin-top: 30px;
    }

    .slide-onsale-container div ul {
        margin-top: 30px;
    }

    .lista-map-office-select {
        width: inherit;
    }


}

@media (max-width:575px) {
    img.icon-sale {
        width: 60px !important;
        max-width: 60px !important;
        object-fit: contain !important;
    }

    .card-categorie {
        width: calc(100% - 20px) !important;
    }

    .blog-card {
        width: 100%;
        margin: 20px;
    }

    .content-article p {
        height: auto;
    }

    .list-map li a {

        font-size: 12px;
        line-height: 18px;
    }

    .info-office-map {
        width: 150px;
    }

    .link-button-right {
        padding: 5px 5px;
        font-size: 10px;
    }

    a.btn-rounded.bg-white {
        text-decoration: none;
        padding: 10px 30px;
        word-break: break-word;
        padding: 10px;
        font-size: 12px;
    }
}

@media (max-width:330px) {
    .info-office-map {
        width: 130px;
    }

}

html,
body {
    height: auto;
}

body {
    margin: 0;
    font-family: 'Encode Sans Regular', "Helvetica Neue", sans-serif;
}


.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #55970C !important;
}

.mat-expansion-panel-body {
    padding: 0 !important;
}

.text-error {
    color: red;
    font-family: 'Encode Sans Regular';
    font-size: 12px;
}




/* NEW MODELS SECTION */
.availables-section {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding: 30px 0;
    text-align: center;
    min-height: 250px;
}

.cards-cars-availables {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.item-model {
    text-align: center;
    margin: 30px 0;
    width: calc(33% - 30px);
    align-self: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    text-decoration: none;
}



.item-model img {
    text-align: center;
    margin: auto;
    width: 250px;
    height: 190px;
    object-fit: contain;
}

.content-model {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    text-align: left;
    padding: 0 15px;
}

.info-model {
    color: #1E4897;
    font-family: 'Encode Sans Regular';
    font-size: 10px;
    line-height: 15px;
    padding-right: 15px;
    width: 70%;
}

.info-model h4 {
    font-size: 18px;
    font-family: 'Encode Sans Bold';
    line-height: 20px;
}

ul.list-info-model {
    list-style: none;
    padding: 0;
    columns: 1;
    color: #1E4897;
    font-size: 10px;
    line-height: 15px;
    font-family: 'Encode Sans Regular';

}

.info-model p {
    margin: auto;
}

@media screen and (max-width:1250px) {
    .availables-section {
        margin: auto 10px;
    }

    .btn-save-rigth {
        width: 85%;
        margin: 10px;
    }
}

@media screen and (max-width: 1200px) {
    .item-model {
        width: calc(50% - 30px);
    }

}

@media screen and (max-width:767px) {
    .content-model {
        display: contents;
    }

    .info-model {
        width: 100%;
        text-align: center;
        padding: 0;
    }

    ul.list-info-model {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }

    .slide-onsale-container div ul {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
}

@media screen and (max-width: 575px) {
    .item-model {
        width: calc(100% - 30px);
        margin: auto;
        margin-bottom: 0px;
    }

    .item-model img {
        text-align: center;
        margin: auto;
        width: 100%;
        height: auto;
        object-fit: cover;
        max-width: 250px;
    }

}



/* .wrapper-progressBar {
    width: 100%
}


.progressBar li {
    list-style-type: none;
    float: left;
    width: 33%;
    position: relative;
    text-align: center;
}

.progressBar li:before {
    content: " ";
    line-height: 30px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background-color: white
}

.progressBar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.progressBar li:first-child:after {
    content: none;
}

.progressBar li.active {
    color: #1E4897;
}

.progressBar li.active:before {
    border-color: #1E4897;
    background-color: #1E4897
}

.progressBar li.active + li:after {
    background-color: #1E4897;
} */



:root {
    --circle-size: clamp(1.5rem, 5vw, 3rem);
    --spacing: clamp(0.25rem, 2vw, 0.5rem);
}

.c-stepper {
    display: flex;
}

.c-stepper__item {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
}

.c-stepper__item:before {
    --size: 3rem;
    content: "";
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    background-color: lightgrey;
    /* background-color: #1E4897; */
    opacity: 0.5;
    margin: 0 auto 1rem;
}

.c-stepper__item:not(:last-child):after {
    content: "";
    position: relative;
    top: calc(var(--circle-size) / 2);
    width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
    left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
    height: 2px;
    background-color: #e0e0e0;
    order: -1;
}

.step-active {
    /* background-color: #1E4897; */
}

.c-stepper__title {
    font-weight: bold;
    font-size: clamp(1rem, 4vw, 1.25rem);
    margin-bottom: 0.5rem;
}

.c-stepper__desc {
    color: grey;
    font-size: clamp(0.85rem, 2vw, 1rem);
    padding-left: var(--spacing);
    padding-right: var(--spacing);
}

.wrapper {
    max-width: 1000px;
    margin: 2rem auto 0;
}



.mt-3 {
    margin-top: 30px;
}

.mb-3 {
    margin-bottom: 30px !important;
}

.md-stepper-horizontal {
    display: table;
    width: 75%;
    margin: 0 auto;
    background-color: #FFFFFF;
    /* box-shadow: 0 3px 8px -6px rgba(0,0,0,.50); */
}

.md-stepper-horizontal .md-step {
    display: table-cell;
    position: relative;
    padding: 24px;
}

.md-stepper-horizontal .md-step:hover,
.md-stepper-horizontal .md-step:active {
    background-color: rgba(0, 0, 0, 0.04);
}

.md-stepper-horizontal .md-step:active {
    border-radius: 15% / 75%;
}

.md-stepper-horizontal .md-step:first-child:active {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.md-stepper-horizontal .md-step:last-child:active {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.md-stepper-horizontal .md-step:hover .md-step-circle {
    background-color: #757575;
}

.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
    display: none;
}

.md-stepper-horizontal .md-step .md-step-circle {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-color: #999999;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
}

.md-stepper-horizontal.green .md-step.active .md-step-circle {
    background-color: #55970C;
}

.md-stepper-horizontal.blue .md-step.active .md-step-circle {
    background-color: #1E4897;
}

.md-stepper-horizontal .md-step.active .md-step-circle {
    background-color: rgb(33, 150, 243);
}

.md-stepper-horizontal .md-step.done .md-step-circle:before {
    font-family: "Font Awesome 5 Free OR Solids OR Brands";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    content: "\f00c";
}

.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
    display: none;
}

.md-stepper-horizontal .md-step.editable .md-step-circle {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.md-stepper-horizontal .md-step.editable .md-step-circle:before {
    font-family: "Font Awesome 5 Free OR Solids OR Brands";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    content: "\f303";
}

.md-stepper-horizontal .md-step .md-step-title {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
}

.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
    text-align: center;
    color: rgba(0, 0, 0, .26);
}

.md-stepper-horizontal .md-step.active .md-step-title {
    font-weight: 600;
    color: rgba(0, 0, 0, .87);
}

.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
    font-weight: 600;
}

.md-stepper-horizontal .md-step .md-step-optional {
    font-size: 12px;
}

.md-stepper-horizontal .md-step.active .md-step-optional {
    color: rgba(0, 0, 0, .54);
}

.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 1px solid #DDDDDD;
}

.md-stepper-horizontal .md-step .md-step-bar-right {
    right: 0;
    left: 50%;
    margin-left: 20px;
}

.md-stepper-horizontal .md-step .md-step-bar-left {
    left: 0;
    right: 50%;
    margin-right: 20px;
}




.filters-container {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding: 30px 0;
}

.filters {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.form-group.filter {
    width: calc(25% - 33px) !important;
    display: block;
}

.form-group.filter.busquedafilters {
    width: calc(20% - 33px) !important;
    display: block;
}

.filters h4 {
    color: #1E4897;
    font-family: 'Encode Sans Bold';
    font-size: 24px;
    line-height: 28px;
}

@media (max-width: 1200px) {
    .form-group.filter {
        margin-bottom: 20px;
    }

    .form-group.filter.busquedafilters {
        width: calc(33.33% - 33px) !important;
        margin-bottom: 20px;
    }

    .filters-container {
        margin: 30px;
    }

    .availables-section {
        margin: auto 30px;
    }
}

@media (max-width: 991px) {
    .form-group.filter.busquedafilters {
        width: calc(50% - 33px) !important;
    }

    .form-group.filter {
        width: calc(50% - 33px) !important;
    }
}

@media (max-width: 575px) {
    .form-group.filter.busquedafilters {
        width: calc(100%) !important;
    }

    .form-group.filter {
        width: calc(100%) !important;
    }

    .formulario-contacto {
        width: 100%;
    }
}


option[disabled] {
    color: gray;
    /* Cambia el color del texto a gris */
    background-color: lightgray;
    /* Cambia el color de fondo a gris claro */
}


.submitted-message {
    text-align: center;
    font-size: 18px;
    font-family: 'Encode Sans Bold';
    color: #1E4897;
    font-weight: 800;
    width: 60%;
}

.grecaptcha-badge { 
    visibility: hidden;
}

.ant-alert, h1, h2, h3, h4, h5, h6 {
    color: #1E4897;
}
.modal-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
}